import { navigateToUrl } from 'single-spa';

import idleMonitorConfig from '../config/idleMonitorConfig';
import urlsConfig from '../config/urlsConfig';

import createTimer, { OnTimeout } from './createTimer';
import logger from './logger';

type IdleMonitor = {
  start: (onTimeout?: OnTimeout) => void;
  stop: () => void;
  enable: () => void;
  disable: () => void;
};

const logoutUrl = `${urlsConfig.autoLogoutPath}?reason=inactivity`;

const defaultTimetoutHandler: OnTimeout = () => {
  logger.warn('Inactivity detected, forcing logout');
  navigateToUrl(logoutUrl);
};

const getOpenDisabledEventsCounter = (): number => {
  const counter = localStorage.getItem(idleMonitorConfig.disabledEventsCounterStorageKey);

  return counter ? parseInt(counter, 10) : 0;
};

const incrementDisabledEventsCounter = () => {
  const counter = getOpenDisabledEventsCounter();
  const newCounter = counter + 1;

  localStorage.setItem(idleMonitorConfig.disabledEventsCounterStorageKey, newCounter.toString());
};

const decreaseDisabledEventsCounterSafe = (counter: number) => (counter > 0 ? counter - 1 : 0);

const decreaseDisabledEventsCounter = () => {
  const counter = getOpenDisabledEventsCounter();
  const newCounter = decreaseDisabledEventsCounterSafe(counter);

  localStorage.setItem(idleMonitorConfig.disabledEventsCounterStorageKey, newCounter.toString());
};

const isIdleMonitorDisabled = (): boolean => getOpenDisabledEventsCounter() > 0;

const createIdleMonitor = (idleTimeout: number): IdleMonitor => {
  const timer = createTimer();
  let onTimeoutCallback: OnTimeout | undefined;

  const resetIdleTimeout = () => {
    timer.start(onTimeoutCallback, idleTimeout);
  };

  const handleUserActivity = () => {
    const timestamp = Date.now();
    localStorage.setItem(idleMonitorConfig.lastActivityStorageKey, timestamp.toString());
    resetIdleTimeout();
  };

  const handleStorageEvent = (event: StorageEvent) => {
    if (event.key === idleMonitorConfig.lastActivityStorageKey) {
      resetIdleTimeout();
    }
  };

  const stop = () => {
    document.removeEventListener('mousemove', handleUserActivity);
    document.removeEventListener('touchstart', handleUserActivity);
    window.removeEventListener('storage', handleStorageEvent);
    timer.stop();
  };

  const start = (onTimeout: OnTimeout = defaultTimetoutHandler) => {
    if (isIdleMonitorDisabled()) {
      return;
    }

    onTimeoutCallback = onTimeout;
    stop();

    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('touchstart', handleUserActivity);
    window.addEventListener('storage', handleStorageEvent);
    handleUserActivity();
  };

  const disable = () => {
    incrementDisabledEventsCounter();
    stop();
  };

  const enable = () => {
    decreaseDisabledEventsCounter();
    start();
  };

  return {
    start,
    stop,
    disable,
    enable,
  };
};

export default createIdleMonitor;

export type { IdleMonitor };
